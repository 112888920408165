
































import { PercentCondition } from '@/includes/logic/FaceControl/FaceControlConditions'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ToggleButton from 'piramis-base-components/src/components/ToggleButton.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ToggleButton
  }
})
export default class PercentConditionView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!:PercentCondition

  get mode() {
    return this.model.mode === "Greater"
  }

  set mode(state: boolean) {
    this.model.mode = state ? "Greater" : "Less"
  }

  isActive(state: any) {
    if (this.model.mode === state) {
      return 'rgba(var(--a-primary), 1)'
    } else {
      return ''
    }
  }
}
